import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ServicesView from '../views/ServicesView.vue'
import StressManagementView from '../views/StressManagementView.vue'
import ClientsView from '../views/ClientsView.vue'
import TestimonialsView from '../views/TestimonialsView.vue'
import ContactView from '../views/ContactView.vue'

const routes: Array<RouteRecordRaw> = [
    { path: '/', component: HomeView },
    { path: '/about', component: AboutView },
    { path: '/services', component: ServicesView },
    { path: '/stress-management', component: StressManagementView },
    { path: '/clients', component: ClientsView },
    { path: '/testimonials', component: TestimonialsView },
    { path: '/contact', component: ContactView },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
